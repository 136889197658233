'use client'

import { useRouter } from 'next/navigation'

import { EmpathicError } from '@/app/components/empathic-error'

export default function NotFound() {
  const router = useRouter()

  return (
    <EmpathicError
      title="Oops! We couldn't find what you're looking for"
      message="It seems we've wandered off the path. But don't worry! Every detour is an opportunity for discovery. Let's find our way back together."
      actionText="Return to familiar ground"
      onAction={() => router.push('/')}
    />
  )
}
